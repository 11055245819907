import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, scrollSpy, smoothScroll } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  // smoothScroll('a[href^="#"]', {offset: 0})

  $(document).scroll(function () {
    var scroll = $(this).scrollTop()
    if ($(window).width() >= 991 && scroll > 0) {
      $('.header').addClass('fixed')
    } else {
      $('.header').removeClass('fixed')
    };
  })
})

$('.carousel.gallery-slider .neos-contentcollection').slick({
  dots: false,
  arrows: true,
  infinite: true,
  speed: 1200,
  cssEase: 'linear',
  ease: 'ease-in',
  slidesToShow: 1,
  autoplay: false,
  centerMode: false,
  useTransform: true,
  variableWidth: true,
  pauseOnHover: true,
  touchMove: true,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        centerMode: true,
        centerPadding: '55px',
        speed: 600
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        variableWidth: false,
        centerPadding: '20px',
        speed: 500
      }
    }
  ]
})
